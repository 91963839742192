.circular-progress-wrapper {
  .CircularProgressbar {
    .CircularProgressbar-path {
      stroke: $primary;
      stroke-linecap: round;
      transition: stroke-dashoffset .5s ease 0s;
    }
    .CircularProgressbar-trail {
      stroke: $gray-200;
    }
    .CircularProgressbar-text {
      fill: $primary;
      font-size: 20px;
      dominant-baseline: middle;
      text-anchor: middle;
    }
  }
}

.queue {
  .header-box-item {
    border-right: $borderLight;
    &:last-child {
      border-right: 0;
    }
  }
  .links-tabs-wrapper {
    min-height: 420px;
    .nav-item {
      .nav-link {
        background-color: $gray-300;
        color: inherit;
        &.active {
          background-color: $white;
          border-color: $gray-300;
          border-bottom-color: transparent;
          position: relative;
          &::before {
            content: "";
            height: 3px;
            background-color: $primary;
            display: block;
            top: -1px;
            left: -1px;
            right: -1px;
            position: absolute;
          }
        }
      }
    }
    .tab-content {
      border: 1px solid $gray-300;
      border-top: none;
    }
    .statuses-list {
      max-height: 440px;
      overflow-y: scroll;
    }
    .links-wrapper {
      .link-row {
        line-height: 35px;
      }
    }
  }
}

