.site-header {
  z-index: 11;

  .header__logo {
    letter-spacing: 1px;

    i {
      width: 28px;
    }
  }

  .top-menu {
    .dropdown-menu {
      right: 0;
      left: auto;

      .dropdown-item {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.site-sidebar {
  margin-top: 150px;

  .site-name-card {
    &__icon {
      top: 12px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .zmdi-settings {
        margin-top: -10px;
      }
    }
  }

  .sidebar-menu {
    .sidebar-link {
      &:hover {
        background-color: darken($light, 10%);
      }

      &:first-child {
        border-top: $borderLight;
      }
    }
  }
}
