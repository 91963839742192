@import "node_modules/bootstrap/scss/variables";

body {
  -webkit-font-smoothing: antialiased;

  * {
    outline: none !important;
  }

  color: $secondary;
  background-color: $white;
}

a {
  text-decoration: none;
}

a.gray {
  color: $gray-800;
}

h2 {
  font-size: 35px;
}

.nav-pills .nav-link {
  color: $gray-800;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover {
  background-color: $gray-300;
  color: $gray-800;
}


.main-wrapper {
  &::before {
    background: darken($primary, 10%);
    content: " ";
    display: block;
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &.minimal {
    &::before {
      height: 60px;
    }
  }
}

.card-block {
  > .form-group:last-child {
    margin-bottom: 0;
  }
}


.text-sm {
  font-size: $font-size-sm;
}

.text-md {
  font-size: $font-size-base;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xlg {
  font-size: $font-size-xlg;
}

.text-blue {
  color: $blue;
}

.border-top-light {
  border-top: $borderLight;
}

.border-bottom-light {
  border-bottom: $borderLight;
}

@mixin flex-shrink {
  flex-grow: 0;
  flex-shrink: 1;
}

.flex-shrink {
  @include flex-shrink;
}

.btn-primary {
  @include button-variant($primary, darken($primary, 7.5%), $white, darken($primary, 10%), lighten($primary, 10%), $white);
}

.btn-light {
  @include button-variant($light, darken($light, 10%), darken($light, 70%), darken($light, 10%), lighten($light, 20%), $gray-900);
}

.btn-outline-light {
  @include button-outline-variant($light, #222222, lighten($light, 5%), $primary);
}


.btn-white {
  border: none;
  color: $white !important;
  background: none !important;

  &:active,
  &:focus {
    box-shadow: none;
    color: $white;
    text-decoration: none;
  }
}

.form-container-centered {
  width: 400px;
  margin: 0 auto;
}

.higher-layer {
  z-index: 1;
}
