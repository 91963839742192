@import "node_modules/bootstrap/scss/variables";

.sites {
  min-height: 400px;

  .row {
    align-items: center;
  }

  .search-button {
    left: 30px;
    opacity: .54;
    top: 11px;
  }

  .progress {
    cursor: pointer;

    .progress-bar a {
      color: $white;
    }

    .tooltip {
      top: -60px;
      left: 50%;
      width: 200px;
      margin-left: -100px;
    }
  }

  .site-row {
    &:hover {
      background-color: $light;
    }

    @media only screen and (max-width: 768px) {
      img {
        margin-bottom: 15px;
      }
    }
  }
}





